// create a react footer component

import React, { Component } from "react";
import { SocialIcon } from "react-social-icons";
import "./Footer.css";

class Footer extends Component {
    render() {
        return (
            <footer className="site-footer">
                {/* <div className="container">
                    <div className="row">

                        <div className="col-xs-6 col-md-3">
                            <h6>Quick Links</h6>
                            <ul className="footer-links">
                                <li><a href="">About Us</a></li>
                                <li><a href="">Contact Us</a></li>
                                <li><a href="">Contribute</a></li>
                                <li><a href="">Privacy Policy</a></li>
                                <li><a href="">Sitemap</a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div>
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">© 2024 Tantalum Technology Corp. All rights reserved.
                            </p>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <SocialIcon url="https://twitter.com/vastime_io" />
                                {/* <SocialIcon url="https://www.reddit.com/r/vastime/" />
                                <SocialIcon url="mailto:info@vastime.io" /> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
