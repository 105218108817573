import React, { Component } from "react";
import Chart from "chart.js/auto";
class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sevenDayHistory: [],
      goalData: [],
    };
  }
  chartRef = React.createRef();

  async componentDidMount() {
    
    let d = new Date();
    let dayOfWeekIndex = d.getDay();
    let dayOfMonth = d.getDate();
    
    let sevenTemp = [];
    let graphData = [];
    let goalData = [];

    if (dayOfWeekIndex === 0) {
      dayOfWeekIndex = 7;
    }

    for (let i = 1 - dayOfWeekIndex; i < 8 - dayOfWeekIndex; i++) {
      let temp = new Date();
      temp.setDate(dayOfMonth + i);
      //console.log(`${dayOfMonth} + ${i} = ${temp.toDateString()}`);
      //console.log(this.formatDate(temp));
      sevenTemp.push(this.formatDate(temp));
      graphData.push({day: this.formatDate(temp), dayTotal: 0});
      if(i > 0){
        goalData.push({day: this.formatDate(temp), dayTotal: 60*60*3});
      }else{
        goalData.push({day: this.formatDate(temp), dayTotal: 0});
      }
    }
    
    // console.log(this.props.userHistoryData[0].date);
    if (this.props.userHistoryData.length[0] !== 0){
      for (let i = 0; i < 7; i++) {
        // console.log(apiData[i].date);
        // console.log(sevenTemp.indexOf(apiData[i].date));
        let dayIndex = -1;
        if(this.props.userHistoryData[i] !== undefined){
          dayIndex = sevenTemp.indexOf(this.props.userHistoryData[i].date);
          
          if(dayIndex !== -1){
            let dayTotal = 0;
            for (let j = 0; j < this.props.userHistoryData[i].timeChunks.length; j++) {
              if (this.props.userHistoryData[i].timeChunks[j].name === "Productive Work") {
                dayTotal += this.props.userHistoryData[i].timeChunks[j].seconds;
              }
            }
            graphData[dayIndex].dayTotal = dayTotal;
          }
        }
      }
    }
    
    // console.log(graphData);
    // console.log(goalData);

    this.setState(
      {
        sevenDayHistory: graphData,
        goalData: goalData,
      },
      () => {
        this.buildChart();
      }
    );
  }

  formatDate = (d) => {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}/${year}`;
  };

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const data = this.state.sevenDayHistory;
    const goalData = this.state.goalData;

    let d = new Date();
    let firstDayOfWeek = new Date(d.setDate(d.getDate() - d.getDay() + 1));
    let lastDayOfWeek = new Date(d.setDate(d.getDate() - d.getDay() + 7));
    const options = { month: "short", day: "numeric" };

    new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        datasets: [
          {
            label: 'Hours of Productive Work',
            data: data.map((row) => row.dayTotal / 3600),
            backgroundColor: "green",
          },
          {
            label: 'Daily Goal',
            data: goalData.map((row) => row.dayTotal / 3600),
            backgroundColor: "grey",
          }
        ],
      },
      options: {
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: `The week of ${firstDayOfWeek.toLocaleDateString("en-US",options)} to ${lastDayOfWeek.toLocaleDateString("en-US", options)}`,
          },
        },
      },
    });
  };

  render() {
    return (
      <div className="graph-container">
        <canvas ref={this.chartRef} />
      </div>
    );
  }
}

export default BarChart;
