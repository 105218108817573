import React, { Fragment } from 'react';
import ElapsedTimeBar from './ElapsedTimeBar';
import "./Vapp.css";
import api from "../api/api";
import UserHistory from './UserHistory';
import LegendPopup from './LegendPopup';
import DeepWorkGraph from './DeepWorkGraph';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class Vapp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      running: false,
      today: "",
      currentTimeMs: 0,
      currentTimeSec: 0,
      currentTimeMin: 0,
      currentSec: 0,
      currentTaskStarted: 28800,
      currentTask: "Break",
      currentColor: "#900D09",
      userName: "",
      userEmail: "",
      timeChunks: [
        {
          "name": "Sleep",
          "color": "#21618C",
          "started": 0,
          "seconds": 28800
        }
      ],
      userHistoryData: [],
      productiveWorkModalOpen: false,
    };
  }

  componentDidMount() {
    this.watch = setInterval(() => this.pace(), 10);
    
    this.setupTimeChunks();

    var d = new Date();
    var seconds = Math.floor((d.getSeconds()) + (d.getMinutes() * 60) + (d.getHours() * 3600));

    if (seconds < 28800) {
      let temp = this.state.timeChunks;
      temp[0].seconds = seconds;
      this.setState({ 
        currentTaskStarted: seconds,
        today: this.formatDate(d),
        timeChunks: temp
      });
    }else {
      this.setState(prev => ({
        currentTaskStarted: seconds,
        today: this.formatDate(d),
        timeChunks: [...prev.timeChunks, { name: "Sleep In", color: "#424242", started: prev.currentTaskStarted, seconds: (seconds - prev.currentTaskStarted) }]
      }))
    }

  }

  async setupTimeChunks() {
    var d = new Date();
    var today = this.formatDate(d);
    await api.getUserData(this.props.email).then(res => {
      if (res.data.history) {
        let temp = res.data.history;
        temp.reverse();
        if (temp[0].date === today) {
          this.setState({ timeChunks: temp[0].timeChunks, userHistoryData: temp });
          this.setState({ currentTaskStarted: temp[0].timeChunks[temp[0].timeChunks.length - 1].started + temp[0].timeChunks[temp[0].timeChunks.length - 1].seconds });
        }else {
          this.setState({ userHistoryData: temp });
        }
      }
    });
  }

  formatTime = (sec) => {
    const date = new Date(null);
    date.setSeconds(sec);
    if (sec > 3600) {
      return date.toISOString().slice(11, 19);
    } else {
      return date.toISOString().slice(14, 19);
    }
  };

  secondsToHms = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if (h > 0) {
        return `${h}h ${m}m ${s}s`;
    }else {
        return `${m}m ${s}s`;
    }
  }

  formatDate = (d) => {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return `${day}/${month}/${year}`;
  }

  pace = () => {
    this.setState({ currentTimeMs: this.state.currentTimeMs + 10 });
    if (this.state.currentTimeMs >= 1000) {
      this.setState({ currentTimeSec: this.state.currentTimeSec + 1 });
      this.setState({ currentTimeMs: 0 });
      var td = new Date();
      var ts = Math.floor((td.getSeconds()) + (td.getMinutes() * 60) + (td.getHours() * 3600));
      this.setState({ currentSec: ts });
      
    }
    if (this.state.currentTimeSec >= 60) {
      this.setState({ currentTimeMin: this.state.currentTimeMin + 1 });
      this.setState({ currentTimeSec: 0 });
    }
  };

  async updateDatabase() {
    var d = new Date();
    const userData = {
      email: this.props.email,
      history: [{
        date: this.formatDate(d),
        timeChunks: this.state.timeChunks,
      }],
    };
    await api.updateUser(userData);
  }

  handleProductiveWorkModalOpen = () => {
    this.setState({productiveWorkModalOpen: true});
  }

  handleProductiveWorkModalCancel = () => {
    this.setState({productiveWorkModalOpen: false});
  }

  handleProductiveWorkModalClose = () => {
    this.setState({productiveWorkModalOpen: false});
    this.newTimeChunk("Productive Work", "green");
  }

  newTimeChunk = (TaskName, color) => {
    var d = new Date();
    var s = Math.floor((d.getSeconds()) + (d.getMinutes() * 60) + (d.getHours() * 3600));

    if((s - this.state.currentTaskStarted) < 45) {
      this.setState({ 
        currentColor: color,
        currentTask: TaskName
      });
    }else {
      this.setState(prev => ({
        timeChunks: [...prev.timeChunks, { name: prev.currentTask, color: prev.currentColor, started: prev.currentTaskStarted, seconds: (s - prev.currentTaskStarted) }]
      }), () => {
        if(this.state.userHistoryData[0].date === this.state.today){
          let temp = this.state.userHistoryData;
          temp[0].timeChunks = this.state.timeChunks;
          this.setState({userHistoryData: temp});
        }else{
          let temp = this.state.userHistoryData;
          temp.unshift({date: this.state.today, timeChunks: this.state.timeChunks});
          this.setState({userHistoryData: temp});
        }
      });
  
      this.setState({ 
        currentColor: color,
        currentTask: TaskName,
        currentTaskStarted: s,
      }, () => {this.updateDatabase()});
    }
  }

  render() {

    const renderTaskButton = (name, color) => {
      <div className='grid-item'>
        <button className='start-task-button'
          style={{ background: this.state.currentTask === name ? color : buttonOffColor }}
          onClick={() => this.newTimeChunk(name, color)}>
          {this.state.currentTask === name ? name : `Start ${name}`}</button>
      </div>
    };

    // const elapsedTim = this.formatTime(this.state.currentSec - this.state.currentTaskStarted);

    var d = new Date();
    var seconds = Math.floor((d.getSeconds()) + (d.getMinutes() * 60) + (d.getHours() * 3600));

    const elapsedTime = this.secondsToHms(seconds - this.state.currentTaskStarted);

    const buttonOffColor = "#334E68";

    return (
      <Fragment>
        <Modal
        open={this.state.productiveWorkModalOpen}
        onClose={this.handleProductiveWorkModalCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={{position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <h2>
            Productive Work Session
          </h2>
          <p>Choose one task to focus on:</p>
          <TextField id="outlined-basic" label="Task" variant="outlined" autoComplete='false' />
          <p>
            Try and focus for at least 45 minutes on this task.
          </p>
          <Button variant="contained" onClick={this.handleProductiveWorkModalClose}>Start</Button>
        </Box>
      </Modal>
        <br />
        
        <div className='time-bar'>
          <div className="timeScale" style={{ width: "100vw" }}>
          <div className="timeScaleText">12am</div>
          <div className="timeScaleText" style={{ left: "16.67vw", position: "absolute", fontSize: "small" }}>4am</div>
          <div className="timeScaleText" style={{ left: "33.33vw", position: "absolute", fontSize: "small" }}>8am</div>
          <div className="timeScaleText" style={{ left: "50vw", position: "absolute", fontSize: "small" }}>12pm</div>
          <div className="timeScaleText" style={{ left: "66.67vw", position: "absolute", fontSize: "small" }}>4pm</div>
          <div className="timeScaleText" style={{ left: "83.33vw", position: "absolute", fontSize: "small" }}>8pm</div>
          </div>
          <ElapsedTimeBar timeChunks={this.state.timeChunks} showCurrentTime={true}/>
          <LegendPopup/>
        </div>
        
        <div className='button-container'>
          <div className='grid-container'>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: this.state.currentTask === "Productive Work" ? "green" : buttonOffColor }}
                onClick={() => this.handleProductiveWorkModalOpen()}>
                {this.state.currentTask === "Productive Work" ? `Currently Working: ${elapsedTime}` : "Start Productive Work"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: (this.state.currentTask === "Admin Work" ? "#7cc47c" : buttonOffColor), color: (this.state.currentTask === "Admin Work" ? "black" : "#cfcfcf") }}
                onClick={() => this.newTimeChunk("Admin Work", "#7cc47c")}>
                {this.state.currentTask === "Admin Work" ? `Currently Working: ${elapsedTime}` : "Start Admin Work"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: this.state.currentTask === "Break" ? "#900D09" : buttonOffColor }}
                onClick={() => this.newTimeChunk("Break", "#900D09")}>
                {this.state.currentTask === "Break" ? `Currently on Break: ${elapsedTime}` : "Start Break"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: this.state.currentTask === "Eat" ? "#AF601A" : buttonOffColor }}
                onClick={() => this.newTimeChunk("Eat", "#AF601A")}>
                {this.state.currentTask === "Eat" ? `Currently Eating: ${elapsedTime}` : "Start Eating"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: (this.state.currentTask === "Exercise" ? "#e0de38" : buttonOffColor), color: (this.state.currentTask === "Exercise" ? "black" : "#cfcfcf") }}
                onClick={() => this.newTimeChunk("Exercise", "#e0de38")}>
                {this.state.currentTask === "Exercise" ? `Currently Exercising: ${elapsedTime}` : "Start Exercising"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: this.state.currentTask === "Read" ? "purple" : buttonOffColor }}
                onClick={() => this.newTimeChunk("Read", "purple")}>
                {this.state.currentTask === "Read" ? `Currently Reading: ${elapsedTime}` : "Start Reading"}</button>
            </div>
            <div className='grid-item'>
              <button className='start-task-button'
                style={{ background: this.state.currentTask === "Sleep" ? "#21618C" : buttonOffColor }}
                onClick={() => this.newTimeChunk("Sleep", "#21618C")}>
                {this.state.currentTask === "Sleep" ? `Currently Sleeping: ${elapsedTime}` : "Start Sleeping"}</button>
            </div>
          </div>
          {this.state.userHistoryData.length > 0 &&
            <DeepWorkGraph key={this.state.userHistoryData[0].timeChunks.length} userHistoryData={this.state.userHistoryData} email={this.props.email}/>
          }
          
        </div>

        <br />

        <UserHistory userHistoryData={this.state.userHistoryData} today={this.state.today} email={this.props.email}/>

      </Fragment>
    );
  }
}

export default Vapp;