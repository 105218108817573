import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

const CreateAccountButton = () => {


  const { loginWithRedirect } = useAuth0();

  return (
    <Button variant="contained" onClick={() => loginWithRedirect({screen_hint: "signup"})}>
        Create Account
    </Button>
  );
};

export default CreateAccountButton;