import React, { Fragment, PureComponent } from "react";
import ElapsedTimeBar from "./ElapsedTimeBar";
import Button from '@mui/material/Button';

import "./UserHistory.css";
import api from "../api/api";

class UserHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      paid: false,
      userId: null,
    };
  }

  async componentDidMount() {
    const response = await api.getPaymentData(this.props.email);
    
    if (response.data.customer.id !== null) {
      this.setState({ userId: response.data.customer.id});
      if (response.data.subscription.data[0].plan.id !== 'price_1MmPvZCWwH9XLny8LAGMRHW5') {
        this.setState({ paid: true });
      }
    }
  }

  async handlePayment()  {
    const response = await api.managePayment(this.state.userId);
    console.log(response.data.url);
    window.location.href = response.data.url;
  }

  render() {
    if (this.state.paid === false) {
      return (
        <Fragment>
          <h2 className="name">Please purchase a subscription to store your history</h2>
        </Fragment>
      );
      
    } else {
      if (this.props.userHistoryData === 0) {
        return <h2 className="name">No history</h2>;
      } else {
        let historyComponents = [];
        historyComponents.push(<h1 className="name">History</h1>);
        for (let i = 0; i < this.props.userHistoryData.length; i++) {
          if (this.props.userHistoryData[i].date !== this.props.today){
            historyComponents.push(
              <Fragment key={i}>
                <h2 className="date">{this.props.userHistoryData[i].date}</h2>
                <div className="timeScale" style={{ width: "100vw" }}>
                  <div className="timeScaleText">12am</div>
                  <div className="timeScaleText" style={{ left: "16.67vw", position: "absolute", fontSize: "small" }}>4am</div>
                  <div className="timeScaleText" style={{ left: "33.33vw", position: "absolute", fontSize: "small" }}>8am</div>
                  <div className="timeScaleText" style={{ left: "50vw", position: "absolute", fontSize: "small" }}>12pm</div>
                  <div className="timeScaleText" style={{ left: "66.67vw", position: "absolute", fontSize: "small" }}>4pm</div>
                  <div className="timeScaleText" style={{ left: "83.33vw", position: "absolute", fontSize: "small" }}>8pm</div>
                </div>
                <ElapsedTimeBar timeChunks={this.props.userHistoryData[i].timeChunks} />
                <br />
              </Fragment>
            );
          }
        }
        return historyComponents;
      }
    }
  }
}

export default UserHistory;
