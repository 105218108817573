import React, {Fragment} from "react";
import ElapsedTimeBar from "./ElapsedTimeBar";
import "./SuccessfullSchedules.css";
import api from "../api/api";

class SuccessfulSchedules extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            benjaminFranklin: [],
            charlesDarwin: []
        }
    }

    async componentDidMount() {
        const response = await api.getUserData("charles@darwin.uk");
        const response2 = await api.getUserData("benjamin@franklin.com");
        this.setState({charlesDarwin: response.data.history[0].timeChunks});
        this.setState({benjaminFranklin: response2.data.history[0].timeChunks});
    }

    render() {

        const idealSchedule = [
            {name: "Sleep", color: "#21618C", started: 0, seconds: 18000},
            {name: "Break", color: "#900D09", started: 18000, seconds: 600},
            {name: "Admin Work", color: "#7cc47c", started: 18600, seconds: 1200},
            {name: "Productive Work", color: "green", started: 19800, seconds: 7200},
            {name: "Break", color: "#900D09", started: 27000, seconds: 600},
            {name: "Admin Work", color: "#7cc47c", started: 27600, seconds: 1200},
            {name: "Productive Work", color: "green", started: 28800, seconds: 7200},
            {name: "Break", color: "#900D09", started: 36000, seconds: 600},
            {name: "Admin Work", color: "#7cc47c", started: 36600, seconds: 1200},
            {name: "Productive Work", color: "green", started: 37800, seconds: 7200},
            {name: "Eat", color: "#AF601A", started: 45000, seconds: 3600},
            {name: "Break", color: "#900D09", started: 48600, seconds: 600},
            {name: "Admin Work", color: "#7cc47c", started: 49200, seconds: 1200},
            {name: "Productive Work", color: "green", started: 50400, seconds: 7200},
            {name: "Break", color: "#900D09", started: 57600, seconds: 600},
            {name: "Admin Work", color: "#7cc47c", started: 58200, seconds: 1200},
            {name: "Productive Work", color: "green", started: 59400, seconds: 7200},
            {name: "Eat", color: "#AF601A", started: 66600, seconds: 3600},
            {name: "Read", color: "purple", started: 70200, seconds: 3600},
            {name: "Sleep", color: "#21618C", started: 73800, seconds: 12600}
           
        ]


        return (
            <div className="container">
                <Fragment>
                    <div className="timeScale" style={{ width: "100vw" }}>
                        <div className="timeScaleText">12am</div>
                        <div className="timeScaleText" style={{ left: "16.67vw", position: "absolute", fontSize: "small" }}>4am</div>
                        <div className="timeScaleText" style={{ left: "33.33vw", position: "absolute", fontSize: "small" }}>8am</div>
                        <div className="timeScaleText" style={{ left: "50vw", position: "absolute", fontSize: "small" }}>12pm</div>
                        <div className="timeScaleText" style={{ left: "66.67vw", position: "absolute", fontSize: "small" }}>4pm</div>
                        <div className="timeScaleText" style={{ left: "83.33vw", position: "absolute", fontSize: "small" }}>8pm</div>
                    </div>
                    <ElapsedTimeBar timeChunks={idealSchedule} showCurrentTime={false}/>
                    <br/>

                    <h2 className="name">Benjamin Franklin's Daily Schedule:</h2>
                    <div className="timeScale" style={{ width: "100vw" }}>
                        <div className="timeScaleText">12am</div>
                        <div className="timeScaleText" style={{ left: "16.67vw", position: "absolute", fontSize: "small" }}>4am</div>
                        <div className="timeScaleText" style={{ left: "33.33vw", position: "absolute", fontSize: "small" }}>8am</div>
                        <div className="timeScaleText" style={{ left: "50vw", position: "absolute", fontSize: "small" }}>12pm</div>
                        <div className="timeScaleText" style={{ left: "66.67vw", position: "absolute", fontSize: "small" }}>4pm</div>
                        <div className="timeScaleText" style={{ left: "83.33vw", position: "absolute", fontSize: "small" }}>8pm</div>
                    </div>
                    <ElapsedTimeBar timeChunks={this.state.benjaminFranklin} showCurrentTime={false}/>
                   
                    <br/>
                    {/* <h2 className="name">Charles Darwin</h2>
                    <ElapsedTimeBar timeChunks={this.state.charlesDarwin}/>
                    <br/> */}
                </Fragment>
            </div>
        );
    }
}

export default SuccessfulSchedules;