import React from "react";
import "./Product.css";
import SuccessfullSchedules from "./components/SuccessfullSchedules.js";
import Vapp from "./components/Vapp.js";
import CreateAccountButton from "./components/CreateAccountButton.js";

import productDemo from "./Img/vastime-product.png";
import historyDemo from "./Img/vastime-history-example.png";
import productiveWork from "./Img/productive-work.png";
import weeklyGraphDemo from "./Img/weekly-graph.png";
import toDoList from "./Img/to-do-list.png";
import adminWork from "./Img/multitasking-cartoon.png";

import { useAuth0 } from "@auth0/auth0-react";
import Slide from "react-reveal/Slide";

const Product = () => {
  const { user, isAuthenticated } = useAuth0();

  // console.log(user);
  // console.log(user.nickname);

  if (isAuthenticated) {
    return <Vapp email={user.email} />;
  } else {
    return (
      <div className="App">
        <section className="hero">
          <div className="container">
            <div className="left-col">
              <h1>Get More Done</h1>
              <h3>
                Time track your day and visualize where your time goes.
              </h3>
            </div>
            <Slide bottom>
              <img src={productDemo} alt="vastime dashboard" className="hero-img"/>
            </Slide>
          </div>
        </section>

        <section className="features-section">
          <div className="container">
            <h1>Visualize how you spend the 24 hours of the day</h1>
            <SuccessfullSchedules />
          </div>
        </section>


        {/* <section className="features-section">
          <div className="features-tile-container">
            <Slide left>
                <img className="features-tile-img" src={productiveWork} alt="legend"></img>
            </Slide>
            <Slide right>
                <div className="features-tile-text-container">
                    <h2>Productive Work</h2>
                    <h3>
                        Time spent doing work that is directly related to your goals.
                        Depending on what you are trying to achieve this could mean
                        writing, programming or studying.
                    </h3>
                </div>
            </Slide>
          </div>

          <div className="features-tile-container">
            <Slide left>
                <div className="features-tile-text-container">
                    <h2>Admin Work</h2>
                    <h3>
                        Any work that does not directly move you closer to achieving
                        your goals. This could mean reading emails, paperwork, attending
                        meetings, or organizing.
                    </h3>
                </div>
            </Slide>
            <Slide right>
                <img className="features-tile-img-desktop-only" src={adminWork} alt="legend"></img>
            </Slide>
          </div>

          <div className="features-tile-container">
            <Slide left>
                <img className="features-tile-img" src={toDoList} alt="legend"></img>
            </Slide>
            <Slide right>
                <div className="features-tile-text-container">
                    <h2>Get more acomplished while feeling less busy</h2>
                    <h3>
                    Vastime will encourage you to increase the amount of productive work
                    you do while minimizing admin work.
                    </h3>
                </div>
            </Slide>
          </div>
        </section> */}

        {/* <section className="history-container">
          <div>
            <div className="histoty-title">
              <h1>Analize your history</h1>

            </div>
            <Slide bottom>
              <img src={historyDemo} alt="Illustration" className="history-img" />
            </Slide>
          </div>
        </section> */}


        {/* <section className="features-section">
            <div className="container">
                <stripe-pricing-table pricing-table-id="prctbl_1MheUWCWwH9XLny8ymyXxkz5"
                    publishable-key="pk_live_51MdiN7CWwH9XLny8uhedm3uiqiJv5ttkPlPPR54oo6C5ENTXA5LQ0zsu2L0OXULAkgjiKeFud7cnqfpmBqPLrlCR00Ta530Av4">
                </stripe-pricing-table>
            </div>
        </section> */}
      </div>
    );
  }
};

export default Product;
