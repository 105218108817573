import React, { Component, Fragment, useState } from 'react';
import "./ElapsedTimeBar.css";
import useWindowDimensions from '../hooks/useWindowDimentions.js';
import { Tooltip } from '@mui/material';

const secondsToHms = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    if (h > 0) {
        return `${h}h ${m}m`;
    }else {
        return `${m}m`;
    }
}

const secondsToTime = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    if (m < 10) {
        m = "0" + m;
    }

    if (h > 12){
        h = h - 12;
        return `${h}:${m} pm`;
    }else if(h === 0){
        return `12:${m} am`;
    }else{
        return `${h}:${m} am`;
    }

}

const ElapsedTimeBar = (props) => {
    
    let {height, width} = useWindowDimensions();
    height = height/10;

    var timeChunks = props?.timeChunks || [];
    var showCurrentTime = props?.showCurrentTime || false;

    var d = new Date();
    var seconds = Math.floor((d.getSeconds()) + (d.getMinutes() * 60) + (d.getHours() * 3600));

   
    
    return (
        <Fragment>
            <div className="timeScale">
            </div>
            <div className="timeBarContainer" style={{height: height}}>
                <div className="timeBar" style={{width: `${width}px`, height: height}}/>
                {showCurrentTime && <Fragment>
                    <div className="timeBar" style={{
                    width: `3px`,
                    left: `${(width * (seconds / 86400))}px`, 
                    zIndex: 100,
                    height: height,
                    background: "black"}}
                    />
                    <div style={{
                        left: `${(width * (seconds / 86400))}px`,
                        height: height,
                        paddingTop: height,
                        position: "absolute",
                    }}>
                        {d.toLocaleTimeString()}
                    </div>
                </Fragment>
                }
                {timeChunks.map( timeChunk => 
                    <Tooltip 
                    key={`${timeChunk.started}-${timeChunk.name}`} 
                    title={
                        <React.Fragment>
                            <h3 color="inherit">{timeChunk.name}: {secondsToHms(timeChunk.seconds)}</h3>
                            <p>{"Started: " + secondsToTime(timeChunk.started)}</p>
                            <p>{"Finished: " + secondsToTime(timeChunk.started + timeChunk.seconds)}</p>
                        </React.Fragment>
                    }
                    >
                        <div className="timeBar" 
                        style={{ 
                            width: `${Math.ceil(width * (timeChunk.seconds / 86400))}px`, 
                            left: `${(width * (timeChunk.started / 86400))}px`,
                            height: height, 
                            background: `${timeChunk.color}` }} 
                        />
                    </Tooltip>
                )}
            </div>
        </Fragment>
    );
}

export default ElapsedTimeBar;