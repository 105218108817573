import axios from "axios";

const url = "https://vastime-backend-scqkr.ondigitalocean.app";
// const url = "http://localhost:3000";

axios.defaults.baseURL = `${url}/api`;

const api = {
    getUserData(email) {
        // console.log(`getUserData(${email})`);
        return axios.get(`/user/${email}`);
    },
    getPaymentData(email) {
        // console.log(`getPaymentData(${email})`);
        return axios.get(`/gsd?email=${email}`);
    },
    managePayment(userId) {
        // console.log(`managePayment(${userId})`);
        return axios.get(`/manage/${userId}`);
    },
    createUser(userData) {
        // console.log(`createUser(${userData})`);
        return axios.post("/user/new", userData);
    },
    updateUser(userData) {
        // console.log(`updateUser(${userData})`);
        return axios.post(`/user/${userData.email}`, userData);
    },
    deleteUser(email) {
        return axios.delete(`/user/${email}`);
    }
};

export default api;