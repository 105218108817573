import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const LegendPopup = () => (
  <Popup trigger={<button className='legend-popup-button'> Legend </button>} position="right center">
    <div>
        <ul>
            <li><button className='legend-button' style={{ background: "green" }}/> Productive Work</li>
            <li><button className='legend-button' style={{ background: "#7cc47c", color: "black" }}/> Admin Work</li>
            <li><button className='legend-button' style={{ background: "#900D09" }}/> Break</li>
            <li><button className='legend-button' style={{ background: "#AF601A" }}/> Eat</li>
            <li><button className='legend-button' style={{ background: "#e0de38", color: "black" }}/> Exercise</li>
            <li><button className='legend-button' style={{ background: "purple" }}/> Read</li>
            <li><button className='legend-button' style={{ background: "#21618C" }}/> Sleep</li>
            <li><button className='legend-button' style={{ background: "#424242" }}/> Sleep In</li>
        </ul>
    </div>
  </Popup>
);

export default LegendPopup;