import React from "react";

import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Product from "./Product.js";
import Faq from "./Faq.js";
import Blog from "./Blog.js";
import Friends from "./Friends.js";

import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={
          
              <Product />
          
          } />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/friends" element={<Friends />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
