import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import "./Header.css";
import icon from "../Img/vastime-icon.png";
import HeaderMenu from "./HeaderMenu";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import CreateAccountButton from "./CreateAccountButton.js";
import api from "../api/api";

import { useAuth0 } from "@auth0/auth0-react";



const Header = () => {
  const { user, isAuthenticated } = useAuth0();

  async function handlePayment()  {
    console.log("handlePayment");
    let response = await api.getPaymentData(user.email);
    const userId = response.data.customer.id;
    response = await api.managePayment(userId);
    window.location.href = response.data.url;
  }
  
  return (
    <div className="nav-bar">
      <div className="title">Vastime</div>
      <nav>
        {isAuthenticated && (
          <Stack direction="row" spacing={2}>
            {/* <Button href="/" variant="text">
              Dashboard
            </Button>
            <Button to="/friends" variant="text">
              Friends
            </Button> */}
            {/* <HeaderMenu /> */}
            <Button onClick={() => handlePayment()} variant="outlined" >
              Premium
            </Button>
            <LogoutButton className="logoutButton" />
            
          </Stack>
        )}
        {!isAuthenticated && (
          <Stack direction="row" spacing={2}>
              {/* <Button href="/" variant="text">
                Product
              </Button>
              <Button href="/faq" variant="text">
                FAQ
              </Button>
              <Button href="/blog" variant="text">
                Blog
              </Button> */}
              <LoginButton className="loginButton" />
              <CreateAccountButton/>
          </Stack>
          
        )}
      </nav>
    </div>
  );
};

export default Header;
